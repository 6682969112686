import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import config from "../../config";
import { ProfilePic } from "../../constant";

const DashboardSidebar = ({
    auth,
    adminsCount,
    pendingTimelineCount,
    approvedTimelineCount,
    rejectedTimelineCount,
    recentTimelineCount
}) => {
    return (
        <>
            <div className="dashboard_sidebar">
                <div className="profile-header">
                    <div className="profile-img">
                        <figure>
                            <img
                                src={config.returnAvatar(auth.user)}
                                alt=""
                                className="img-fluid"
                            />
                        </figure>
                    </div>
                    <div className="name">
                        <h4>{auth.user.full_name}</h4>
                        <p>Welcome to Admin dashboard</p>
                    </div>
                </div>
                <ul className="menus">
                    <li className="menu-items">
                        <Link to="/dashboard" className="menu-links">
                            Pending Approvals{" "}
                            <span className="noti">{pendingTimelineCount}</span>
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to="/dashboard/recent-addition"
                            className="menu-links"
                        >
                            Recent Addition{" "}
                            <span className="noti">{recentTimelineCount}</span>
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to="/dashboard/member-access"
                            className="menu-links"
                        >
                            Member Access{" "}
                            <span className="noti">{adminsCount}</span>
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/dashboard/add-admin" className="menu-links">
                            Add Admin
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    adminsCount: state.admins.length,
    pendingTimelineCount: state.timeline.pending.data.length,
    recentTimelineCount: state.timeline.recent?.data?.length ?? 0,
    approvedTimelineCount: state.timeline.approved.data.length,
    rejectedTimelineCount: state.timeline.rejected.data.length
});

export default connect(mapStateToProps)(DashboardSidebar);
