import { ADD_ADMIN, GET_ADMINS } from "./type";
import { ADD_ADMIN_ENDPOINT, GET_ADMINS_ENDPOINT } from "../../apis";
import config from "../../config";

export const AddAdminAction = (
    member_id = null,
    firstName = null,
    lastName = null,
    email = null,
    needsApproval = true
) => async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    let data = null;
    if (!member_id) {
        data = JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            email,
            needs_approval: needsApproval ? true : false
        });
    } else {
        data = JSON.stringify({
            member_id,
            needs_approval: needsApproval
        });
    }
    const res = await config.postApi(ADD_ADMIN_ENDPOINT, headers, data);
    if (res) {
        dispatch({ type: ADD_ADMIN, payload: res });
    }
};

export const GetAdminsAction = () => async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const res = await config.getApi(GET_ADMINS_ENDPOINT, headers);
    if (res) {
        dispatch({ type: GET_ADMINS, payload: res });
    }
};
