import React from "react";
import "./style.css";

const MyBtn = ({ title, onClick = null }) => {
    return (
        <button className="btn gradient-btn" type="button" onClick={onClick}>
            {title}
        </button>
    );
};

export default MyBtn;
