import React from 'react'
import Header from '../../components/Header/Header'
import "../../assets/css/style.css"
import Banner from './Banner'
import Protech from './Protech'
import FamilyTree from './FamilyTree'
import Story from './Story'
import DigitalStorage from './DigitalStorage'
import Newsletter from '../../components/Newsletter/Newsletter'
import Footer from '../../components/Footer/Footer'

const Home = () => {
    return (
        <>
            {/* Header Js */}
            <Header />
            {/* Header Js */}

            {/* Banner Sec Start Here */}
            <Banner />
            {/* Banner Sec End Here */}

            {/* Care Sec Start Here */}
            <Protech />
            {/* Care Sec End Here */}

            {/* Family Tree Sec Start Here */}
            <FamilyTree />
            {/* Family Tree Sec End Here */}

            {/* Story Sec Start Here */}
            <Story />
            {/* Story Sec End Here */}

            {/* Digital Storage Sec Start Here */}
            <DigitalStorage />
            {/* Digital Storage Sec End Here */}

            {/* Newsletter Sec Start Here */}
            <Newsletter />
            {/* Newsletter Sec End Here */}

            {/* Footer Sec Start Here */}
            <Footer />
            {/* Footer Sec End Here */}
        </>
    )
}

export default Home
