import { GET_CHATS, CLEAR_CHATS } from "../actions/type";

const chat = (state = [], action) => {
    switch (action.type) {
        case GET_CHATS:
            return action.payload;
        case CLEAR_CHATS:
            return [];
        default:
            return state;
    }
};

export default chat;
