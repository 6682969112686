import { RECENT_TIMELINE_ENDPOINT, TIMLINE_ENDPOINT } from "../../apis";
import config from "../../config";
import {
    TIMELINE_STATUS_APPROVED,
    TIMELINE_STATUS_PENDING,
    TIMELINE_STATUS_REJECTED
} from "../../constant";
import {
    ADD_TIMELINE,
    GET_APPROVED_TIMELINE,
    GET_PENDING_TIMELINE,
    GET_RECENT_TIMELINE,
    GET_REJECTED_TIMELINE,
    GET_TIMELINE
} from "./type";

export const GetTimelineAction = (categoryId = null) => async (
    dispatch,
    getState
) => {
    const headers = config.bearerHeaders(getState().auth.token);
    let url = TIMLINE_ENDPOINT;
    let params = "?";
    if (categoryId) {
        params += "category_id=" + categoryId;
    }
    url += params;

    const res = await config.getApi(url, headers);
    if (res) {
        dispatch({ type: GET_TIMELINE, payload: res });
    }
};

export const GetPendingTimelineAction = (categoryId = null) => async (
    dispatch,
    getState
) => {
    const headers = config.bearerHeaders(getState().auth.token);
    let url = TIMLINE_ENDPOINT;
    let params = "?";
    params += "status=" + TIMELINE_STATUS_PENDING + "&";
    if (categoryId) {
        params += "category_id=" + categoryId;
    }
    url += params;

    const res = await config.getApi(url, headers);
    if (res) {
        dispatch({ type: GET_PENDING_TIMELINE, payload: res });
    }
};

export const GetApprovedTimelineAction = (categoryId = null) => async (
    dispatch,
    getState
) => {
    const headers = config.bearerHeaders(getState().auth.token);
    let url = TIMLINE_ENDPOINT;
    let params = "?";
    params += "status=" + TIMELINE_STATUS_APPROVED + "&";
    if (categoryId) {
        params += "category_id=" + categoryId;
    }
    url += params;

    const res = await config.getApi(url, headers);
    if (res) {
        dispatch({ type: GET_APPROVED_TIMELINE, payload: res });
    }
};
export const GetRejectedTimelineAction = (categoryId = null) => async (
    dispatch,
    getState
) => {
    const headers = config.bearerHeaders(getState().auth.token);
    let url = TIMLINE_ENDPOINT;
    let params = "?";
    params += "status=" + TIMELINE_STATUS_REJECTED + "&";
    if (categoryId) {
        params += "category_id=" + categoryId;
    }
    url += params;
    const res = await config.getApi(url, headers);
    if (res) {
        dispatch({ type: GET_REJECTED_TIMELINE, payload: res });
    }
};

export const GetRecentTimelineAction = () => async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const res = await config.getApi(RECENT_TIMELINE_ENDPOINT, headers);
    if (res) {
        dispatch({ type: GET_RECENT_TIMELINE, payload: res });
    }
};

export const AddTimelineAction = timeline => async (dispatch, getState) => {
    const headers = config.bearerMultipartHeaders(getState().auth.token);
    const res = await config.postApi(TIMLINE_ENDPOINT, headers, timeline);
    if (res) {
        dispatch({ type: ADD_TIMELINE, payload: res });
    }
};
