import {
    ADD_TIMELINE,
    CLEAR_TIMELINE,
    GET_APPROVED_TIMELINE,
    GET_PENDING_TIMELINE,
    GET_REJECTED_TIMELINE,
    GET_TIMELINE,
    LOAD_MORE_APPROVED_TIMELINE,
    LOAD_MORE_PENDING_TIMELINE,
    LOAD_MORE_REJECTED_TIMELINE,
    GET_RECENT_TIMELINE,
    LOAD_MORE_RECENT_TIMELINE
} from "../actions/type";

let initialState = {
    all: { data: [], next_page_url: "" },
    pending: { data: [], next_page_url: "" },
    approved: { data: [], next_page_url: "" },
    rejected: { data: [], next_page_url: "" },
    recent: { data: [], next_page_url: "" }
};

const timeline = (state = initialState, action) => {
    switch (action.type) {
        case GET_TIMELINE:
            return { ...state, all: action.payload };
        case ADD_TIMELINE:
            return { ...state, all: { ...state.all, data: action.payload } };
        case GET_PENDING_TIMELINE:
            return { ...state, pending: action.payload };
        case LOAD_MORE_PENDING_TIMELINE:
            return {
                ...state,
                pending: {
                    ...state,
                    data: [...state.data, action.payload.data],
                    next_page_url: action.payload.next_page_url
                }
            };
        case GET_APPROVED_TIMELINE:
            return { ...state, approved: action.payload };
        case LOAD_MORE_APPROVED_TIMELINE:
            return {
                ...state,
                approved: {
                    ...state,
                    data: [...state.data, action.payload.data],
                    next_page_url: action.payload.next_page_url
                }
            };
        case GET_REJECTED_TIMELINE:
            return { ...state, rejected: action.payload };
        case LOAD_MORE_REJECTED_TIMELINE:
            return {
                ...state,
                rejected: {
                    ...state,
                    data: [...state.data, action.payload.data],
                    next_page_url: action.payload.next_page_url
                }
            };
        case GET_RECENT_TIMELINE:
            return { ...state, recent: action.payload };
        case LOAD_MORE_RECENT_TIMELINE:
            return {
                ...state,
                recent: {
                    ...state,
                    data: [...state.data, action.payload.data],
                    next_page_url: action.payload.next_page_url
                }
            };
        case CLEAR_TIMELINE:
            return { ...initialState };
        default:
            return state;
    }
};

export default timeline;
