import {
    ADD_POST,
    CLEAR_POSTS,
    GET_MORE_POSTS,
    GET_POSTS
} from "../actions/type";

let initialState = {
    data: [],
    next_page_url: ""
};

const posts = (state = initialState, action) => {
    switch (action.type) {
        case GET_POSTS:
            return action.payload;
        case GET_MORE_POSTS:
            return {
                ...state,
                data: [...state.data, ...action.payload.data],
                next_page_url: action.payload.next_page_url
            };
        case ADD_POST:
            return { ...state, data: [action.payload, ...state.data] };
        case CLEAR_POSTS:
            return { ...initialState };
        default:
            return state;
    }
};

export default posts;
