import React from "react";
import { Spinner } from "reactstrap";
import "../assets/css/overlay-spinner.css";

const OverlaySpinner = () => {
    return (
        <div className="spinner_container">
            <Spinner />
        </div>
    );
};

export default OverlaySpinner;
