import { GET_ADMINS, ADD_ADMIN, CLEAR_ADMINS } from "../actions/type";

const admins = (state = [], action) => {
    switch (action.type) {
        case GET_ADMINS:
            return action.payload;
        case ADD_ADMIN:
            return [...state, action.payload];
        case CLEAR_ADMINS:
            return [];
        default:
            return state;
    }
};

export default admins;
