import {
    ADD_PAYMENT_METHODS,
    GET_PAYMENT_METHODS,
    CLEAR_PAYMENT_METHODS
} from "../actions/type";

const paymentMethods = (state = [], action) => {
    switch (action.type) {
        case GET_PAYMENT_METHODS:
            return action.payload;
        case ADD_PAYMENT_METHODS:
            return [...state, action.payload];
        case CLEAR_PAYMENT_METHODS:
            return [];
        default:
            return state;
    }
};

export default paymentMethods;
