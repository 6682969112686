import { LOGIN, LOGOUT, REGISTER, UPDATE_PROFILE } from "../actions/type";

let initialState = {
    user: {},
    token: null
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return action.payload;
        case REGISTER:
            return action.payload;
        case UPDATE_PROFILE:
            return { ...state, user: action.payload };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default auth;
