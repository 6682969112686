import { RELATIONS_ENDPOINT } from "../../apis";
import config from "../../config";
import { GET_RELATIONS } from "./type";

export const GetRelationsAction = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    const headers = config.bearerHeaders(token);
    const res = await config.getApi(RELATIONS_ENDPOINT, headers);
    if (res) {
        dispatch({ type: GET_RELATIONS, payload: res });
    }
};
