import React from "react";
import useClipboard from "react-use-clipboard";

const Quote = ({ content }) => {
    const [copy, setCopy] = useClipboard(content);

    return (
        <div className="quote_box">
            <button onClick={setCopy} className="copybtn">
                <i className="fa fa-clone" aria-hidden="true"></i>
            </button>
            <textarea
                className="form-control quote"
                readOnly
                value={`"${content}"`}
            />
        </div>
    );
};

export default Quote;
