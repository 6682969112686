import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import persistedReducer from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const persistedStore = persistStore(store);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  store,
  persistedStore,
};
