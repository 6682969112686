import React from "react";
import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";

const MyInput = ({
    value,
    onChange = null,
    placeholder = "",
    type = "text",
    errors = [],
    label = "",
    showLabel = false,
    inputId = "",
    accept = "",
    textarea = false,
    disabled = false,
    placeholderBellowText = ""
}) => {
    if (!textarea) {
        return (
            <div className="form-group">
                {showLabel && <label htmlFor={inputId}>{label}</label>}
                <input
                    disabled={disabled}
                    id={inputId}
                    type={type}
                    placeholder={placeholder}
                    className={
                        "form-control " +
                        (errors.length > 0 ? "is-invalid" : "")
                    }
                    value={value}
                    onChange={onChange}
                    accept={accept}
                />
                {placeholderBellowText && (
                    <FormText color="muted">{placeholderBellowText}</FormText>
                )}
                <div className="invalid-feedback">{errors.map(err => err)}</div>
            </div>
        );
    }
    return (
        <FormGroup>
            {showLabel && <Label htmlFor={inputId}>{label}</Label>}
            <Input
                disabled={disabled}
                type="textarea"
                id={inputId}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                invalid={errors.length > 0}
            />

            <FormFeedback>{errors.map(err => err)}</FormFeedback>
        </FormGroup>
    );
};

export default MyInput;
