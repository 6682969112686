import React from 'react'
import { Link } from 'react-router-dom';

const InnerBanner = (porps) => {
    const PageName = porps.PageName;
    return (
        <>
            <section className="inner_banner">
                <div className="container">
                    <div className="title">
                        <h3>{PageName}</h3>
                    </div>
                    {/* <ul className="breadcrumbs">
                        <li><Link to="#">Home</Link></li>
                        <li>{PageName}</li>
                    </ul> */}
                </div>
            </section>
        </>
    )
}

export default InnerBanner
