import React from "react";
import moment from "moment";
import config from "../../config";

const ReceivedMessage = ({ message }) => {
    return (
        <div className="recive-message">
            <div className="recieve-from-img">
                <img
                    style={{ height: 45, width: 45, borderRadius: "100%" }}
                    src={config.returnAvatar(message.from)}
                    alt=""
                />
            </div>
            <div className="messages">
                <div className="message-recieved">
                    <div className="that-message">
                        <span>{message.message}</span>
                    </div>
                    <div className="recieved-on">
                        <span>
                            {moment(message.created_at).format("hh:mm A")}
                        </span>
                    </div>
                </div>
                {/* <div className="message-recieved">
                    <div className="that-message">
                        <span>Duis aute irure dolor in reprehenderit</span>
                    </div>
                    <div className="recieved-on">
                        <span>08:08 am</span>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default ReceivedMessage;
