import React from "react";
import config from "../../config";

const Comment = ({ user, comment, id }) => {
    return (
        <>
            <div className="single-comment-wrapper">
                <div className="single-comment-img-wrapper">
                    <img
                        src={config.returnAvatar(user)}
                        className="comment-img"
                        alt=""
                    />
                </div>
                <div className="comment-content-wrapper">
                    <h6 className="profile-name">
                        <a href="#">{user.full_name}</a>
                    </h6>
                    <p className="comment-des">{comment}</p>
                </div>
            </div>
        </>
    );
};

export default Comment;
