import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Spinner
} from "reactstrap";
import React, { useState } from "react";
import config from "../../config";
import { connect } from "react-redux";
import MyInput from "../../components/MyInput";

const AddMemberModal = ({
    isOpen = false,
    toggle,
    onSubmit = null,
    members = [],
    spinner = false,
    user
}) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gender, setGender] = useState();
    const [dob, setDob] = useState();
    const [connectionName, setConnectionName] = useState();
    const [relationToUserId, setRelationToUserId] = useState();
    const [relationOfName, setRelationOfName] = useState("");
    const [relationToName, setRelationToName] = useState("");
    const [avatar, setAvatar] = useState({});
    const [deceased, setDeceased] = useState(false);
    const [dod, setDod] = useState(false);

    const [loading, setLoading] = useState(false);

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add Member Modal</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>First Name</Label>
                            <Input
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                placeholder="Enter First Name"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Last Name</Label>
                            <Input
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                placeholder="Enter Last Name"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Gender</Label>
                            <Input
                                type="select"
                                value={gender}
                                onChange={e => setGender(e.target.value)}
                            >
                                <option value={null} selected disabled>
                                    Select Gender
                                </option>
                                <option>Male</option>
                                <option>Female</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Date of Birth</Label>
                            <Input
                                type="date"
                                value={dob}
                                onChange={e => setDob(e.target.value)}
                                placeholder="Select Date of Birth"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Relation</Label>
                            <Input
                                type="select"
                                value={connectionName}
                                onChange={e =>
                                    setConnectionName(e.target.value)
                                }
                            >
                                <option value={null} disabled selected>
                                    Select Relation
                                </option>
                                <option>Son</option>
                                <option>Daughter</option>
                                <option>Father</option>
                                <option>Mother</option>
                                <option>Wife</option>
                                <option>Husband</option>
                                <option>Brother</option>
                                <option>Sister</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Relation to</Label>
                            <Input
                                type="select"
                                value={relationToUserId}
                                onChange={e => {
                                    setRelationToName(
                                        e.target.options[e.target.selectedIndex]
                                            .dataset.fullName
                                    );
                                    setRelationToUserId(e.target.value);
                                }}
                            >
                                <option value={null} disabled selected>
                                    Select Relation To User
                                </option>
                                {members.map(member => {
                                    return (
                                        <option
                                            key={member.id.toString()}
                                            data-full-name={member.full_name}
                                            value={member.id}
                                        >
                                            {member.full_name}
                                        </option>
                                    );
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <MyInput
                            inputId="avatar"
                            // value={avatar}
                            label="Add Image"
                            showLabel
                            onChange={e => setAvatar(e.target.files[0])}
                            type="file"
                            accept="image/*"
                        />
                    </Col>
                    <Col md={6}>
                        <FormGroup check>
                            <Input
                                id="deceased"
                                type="checkbox"
                                value={deceased}
                                onChange={e => {
                                    setDeceased(e.target.checked);
                                }}
                            />
                            <Label htmlFor="deceased">Deceased</Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    {deceased && (
                        <Col md={6}>
                            <MyInput
                                inputId="dod"
                                // value={avatar}
                                label="Date of Death"
                                showLabel
                                onChange={e => setDod(e.target.value)}
                                type="date"
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col md={12}>
                        <p>{`${firstName} ${lastName} is ${connectionName ||
                            ""} of ${relationToName}`}</p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {spinner ? (
                    <Spinner style={{ color: config.primaryColor }} />
                ) : null}
                <Button
                    style={{ backgroundColor: config.primaryColor }}
                    onClick={() => {
                        let dataObject = {
                            firstName,
                            lastName,
                            dob,
                            gender,
                            relationToUserId,
                            connectionName,
                            avatar,
                            dod,
                            deceased
                        };
                        setFirstName("");
                        setLastName("");
                        setDob(null);
                        setGender(null);
                        setRelationToUserId(null);
                        setConnectionName(null);
                        setAvatar({});
                        setDod(null);
                        setDeceased(false);
                        onSubmit(dataObject);
                    }}
                    disabled={spinner}
                >
                    Add
                </Button>
                <Button color="danger" onClick={toggle} disabled={spinner}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps)(AddMemberModal);
