import React, { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Spinner
} from "reactstrap";
import config from "../../config";

const AddConnectionModal = ({
    isOpen = false,
    toggle,
    onSubmit = null,
    members = [],
    spinner = false
}) => {
    const [relationOfId, setRelationOfId] = useState(null);
    const [relationToId, setRelationToId] = useState(null);
    const [relationOfName, setRelationOfName] = useState("");
    const [relationToName, setRelationToName] = useState("");
    const [connectionName, setConnectionName] = useState();

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add Member Connection</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Relation Of</Label>
                            <Input
                                type="select"
                                value={relationOfId}
                                onChange={e => {
                                    setRelationOfName(
                                        e.target.options[e.target.selectedIndex]
                                            .dataset.fullName
                                    );
                                    setRelationOfId(e.target.value);
                                }}
                            >
                                <option value={null} disabled selected>
                                    Select Relation Of
                                </option>
                                {members.map(member => {
                                    if (member.id != relationToId) {
                                        return (
                                            <option
                                                key={member.id.toString()}
                                                data-full-name={
                                                    member.full_name
                                                }
                                                value={member.id}
                                            >
                                                {member.full_name}
                                            </option>
                                        );
                                    }
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Relation To</Label>
                            <Input
                                type="select"
                                value={relationToId}
                                onChange={e => {
                                    setRelationToName(
                                        e.target.options[e.target.selectedIndex]
                                            .dataset.fullName
                                    );
                                    setRelationToId(e.target.value);
                                }}
                            >
                                <option value={null} disabled selected>
                                    Select Relation To
                                </option>
                                {members.map(member => {
                                    if (member.id != relationOfId) {
                                        return (
                                            <option
                                                key={member.id.toString()}
                                                data-full-name={
                                                    member.full_name
                                                }
                                                value={member.id}
                                            >
                                                {member.full_name}
                                            </option>
                                        );
                                    }
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Connection Name</Label>
                            <Input
                                type="select"
                                value={connectionName}
                                onChange={e =>
                                    setConnectionName(e.target.value)
                                }
                            >
                                <option value={null} disabled selected>
                                    Select Relation
                                </option>
                                <option>Son</option>
                                <option>Daughter</option>
                                <option>Father</option>
                                <option>Mother</option>
                                <option>Wife</option>
                                <option>Husband</option>
                                <option>Brother</option>
                                <option>Sister</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>{`${relationOfName} is ${connectionName} of ${relationToName}`}</p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {spinner ? (
                    <Spinner style={{ color: config.primaryColor }} />
                ) : null}
                <Button
                    style={{ backgroundColor: config.primaryColor }}
                    onClick={() =>
                        onSubmit({ relationOfId, relationToId, connectionName })
                    }
                    disabled={spinner}
                >
                    Add
                </Button>
                <Button color="danger" onClick={toggle} disabled={spinner}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddConnectionModal;
