import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/login.css";
import { Link, useNavigate } from "react-router-dom";
import { LoginAction } from "../../redux/actions/auth";
import { connect } from "react-redux";
import FormException from "../../exceptions/FormException";
import { Spinner } from "reactstrap";

const SignIn = ({ LoginAction }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);

    const submit = async () => {
        try {
            setSpinner(true);
            await LoginAction(email, password);
            setSpinner(false);
            navigate("/profile");
        } catch (e) {
            setSpinner(false);
            if (e instanceof FormException) {
                setErrors(e.errors);
            }
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="Sign In" />
            {/* Inner Banner End Here */}

            {/* Login Sec Start Here */}
            <section className="login_sec text-center">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        {/* <div className="col-lg-6">
                            <div className="img-box">
                                <figure><img src={AboutPic} alt="" className="img-fluid" /></figure>
                            </div>
                        </div> */}
                        <div className="col-lg-6">
                            <div className="form-box">
                                <div className="heading-wrapper">
                                    {/* <h2>Sign In</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        </p> */}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        className={
                                            `form-control ` +
                                            (errors.email &&
                                            errors.email.length > 0
                                                ? "is-invalid"
                                                : "")
                                        }
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    {errors.email && errors.email.length > 0 ? (
                                        <div className="invalid-feedback">
                                            {errors.email.map(error => error)}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        className={
                                            `form-control ` +
                                            (errors.password &&
                                            errors.password.length > 0
                                                ? "is-invalid"
                                                : "")
                                        }
                                        value={password}
                                        onChange={e =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    {errors.password &&
                                    errors.password.length > 0 ? (
                                        <div className="invalid-feedback">
                                            {errors.password.map(
                                                error => error
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="remeberdv">
                                    <div className="form-group">
                                        <input
                                            type="checkbox"
                                            id="Remeber_me"
                                            className="form-check-input"
                                        />
                                        <label for="Remeber_me">
                                            Remember Me
                                        </label>
                                    </div>
                                    <div className="link">
                                        <Link to="/forgot-pass">
                                            Forget Password?
                                        </Link>
                                    </div>
                                </div>
                                <div className="button-group">
                                    <button
                                        type="button"
                                        onClick={submit}
                                        className="btn"
                                        disabled={spinner}
                                    >
                                        {spinner ? <Spinner /> : "Login"}
                                    </button>
                                </div>
                                <div className="bottom-bar">
                                    <p>
                                        Don't have account?{" "}
                                        <Link to="/signup">Sign Up</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* login Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    LoginAction: async (email, password) =>
        dispatch(LoginAction(email, password))
});

export default connect(null, mapDispatchToProps)(SignIn);
