import React, { useEffect } from "react";
import { connect } from "react-redux";
import { UPDATE_TIMELINE_ENDPOINT } from "../../apis";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import config from "../../config";
import {
    TIMELINE_STATUS_APPROVED,
    TIMELINE_STATUS_REJECTED
} from "../../constant";
import { GetPendingTimelineAction } from "../../redux/actions/timeline";
import DashboardSidebar from "./DashboardSidebar";

const PendingApprovals = ({ auth, pendingTimeline, getTimeline }) => {
    useEffect(() => {
        loadTimeline().then();
    }, []);

    const updateTimeline = async (timelineId, status) => {
        const headers = config.bearerHeaders(auth.token);
        await config.postApi(
            UPDATE_TIMELINE_ENDPOINT(timelineId),
            headers,
            JSON.stringify({ status })
        );
        await loadTimeline();
    };

    const loadTimeline = async () => {
        try {
            await getTimeline();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Start Here */}
            <InnerBanner PageName="Dashboard" />
            {/* InnerBanner End Here */}

            {/* Dashboard Sec Start Here */}
            <section className="dashboard_sec">
                <div className="container">
                    <div className="admin_box">
                        <div className="row">
                            <div className="col-lg-3">
                                <DashboardSidebar />
                            </div>
                            <div className="col-lg-8">
                                <div className="approval_box">
                                    <div className="module_title">
                                        <h3>Pending Approvals</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="name">
                                                        Name
                                                    </th>
                                                    <th className="Title">
                                                        Title
                                                    </th>
                                                    <th className="content">
                                                        Content
                                                    </th>
                                                    <th className="tags">
                                                        Tags
                                                    </th>
                                                    <th className="apporval">
                                                        Approval
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pendingTimeline.data.map(
                                                    singlePendingTimeline => (
                                                        <tr
                                                            key={singlePendingTimeline.id.toString()}
                                                        >
                                                            <td className="name">
                                                                {
                                                                    singlePendingTimeline
                                                                        .posted_by
                                                                        ?.full_name
                                                                }
                                                            </td>
                                                            <td className="Title">
                                                                {
                                                                    singlePendingTimeline.title
                                                                }
                                                            </td>
                                                            <td className="content">
                                                                {
                                                                    singlePendingTimeline
                                                                        .media[0]
                                                                        ?.media_type
                                                                }
                                                            </td>
                                                            <td className="tags">
                                                                <ul className="all-tags">
                                                                    {singlePendingTimeline.tags.map(
                                                                        tag => (
                                                                            <li
                                                                                key={`${singlePendingTimeline.id}_${tag}`}
                                                                            >
                                                                                <span>
                                                                                    {
                                                                                        tag
                                                                                    }
                                                                                </span>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </td>
                                                            <td className="apporval">
                                                                <div className="button-group">
                                                                    <button
                                                                        className="approved"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            updateTimeline(
                                                                                singlePendingTimeline.id,
                                                                                TIMELINE_STATUS_APPROVED
                                                                            )
                                                                        }
                                                                    >
                                                                        <i
                                                                            className="fa fa-check"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </button>
                                                                    <button
                                                                        className="denied"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            updateTimeline(
                                                                                singlePendingTimeline.id,
                                                                                TIMELINE_STATUS_REJECTED
                                                                            )
                                                                        }
                                                                    >
                                                                        <i
                                                                            className="fa fa-times"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                                {/* <tr>
                                                    <td className="name">
                                                        Nigel
                                                    </td>
                                                    <td className="Title">
                                                        Anniversary
                                                    </td>
                                                    <td className="content">
                                                        MP4/Pdf
                                                    </td>
                                                    <td className="tags">
                                                        <ul className="all-tags">
                                                            <li>
                                                                <span>
                                                                    Mom's
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    Couple
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td className="apporval">
                                                        <div className="button-group">
                                                            <button className="approved">
                                                                <i
                                                                    className="fa fa-check"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                            <button className="denied">
                                                                <i
                                                                    className="fa fa-times"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="name">
                                                        Oliver Ross
                                                    </td>
                                                    <td className="Title">
                                                        Birthday
                                                    </td>
                                                    <td className="content">
                                                        MP4/Pdf
                                                    </td>
                                                    <td className="tags">
                                                        <ul className="all-tags">
                                                            <li>
                                                                <span>
                                                                    Mom's
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    Birthday
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td className="apporval">
                                                        <div className="button-group">
                                                            <button className="approved">
                                                                <i
                                                                    className="fa fa-check"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                            <button className="denied">
                                                                <i
                                                                    className="fa fa-times"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="name">
                                                        Nigel
                                                    </td>
                                                    <td className="Title">
                                                        Anniversary
                                                    </td>
                                                    <td className="content">
                                                        MP4/Pdf
                                                    </td>
                                                    <td className="tags">
                                                        <ul className="all-tags">
                                                            <li>
                                                                <span>
                                                                    Mom's
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    Couple
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td className="apporval">
                                                        <div className="button-group">
                                                            <button className="approved">
                                                                <i
                                                                    className="fa fa-check"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                            <button className="denied">
                                                                <i
                                                                    className="fa fa-times"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Dashboard Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    pendingTimeline: state.timeline.pending,
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getTimeline: async (categoryId = null) =>
        dispatch(await GetPendingTimelineAction(categoryId))
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingApprovals);
