import { GET_QUOTES, CLEAR_QUOTES } from "../actions/type";

const quotes = (state = [], action) => {
    switch (action.type) {
        case GET_QUOTES:
            return action.payload;
        case CLEAR_QUOTES:
            return [];
        default:
            return state;
    }
};

export default quotes;
