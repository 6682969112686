import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NOTIFICATIONS_ENDPOINT } from "../../apis";
import "../../assets/css/block-list.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import config from "../../config";

const Blocklist = () => {
    const authToken = useSelector(state => state.auth.token);

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = async () => {
        const headers = config.bearerHeaders(authToken);
        const res = await config.getApi(NOTIFICATIONS_ENDPOINT, headers);
        if (res) {
            setNotifications(res);
        }
    };

    return (
        <>
            {/* Header */}
            <Header />
            {/* Header */}

            <section className="block-list">
                <div className="container">
                    <div className="block-list-box">
                        <div className="heading">
                            <h2>Notifications</h2>
                        </div>
                        <div className="all-followers">
                            {notifications?.map((data, index) => (
                                <div
                                    className="follow-box"
                                    key={`notification_${data.id}`}
                                >
                                    {data.type ===
                                    "App\\Notifications\\NewMessage" ? (
                                        <>
                                            <div className="left-side">
                                                <figure>
                                                    <img
                                                        style={{
                                                            // width: 45,
                                                            // height: 45,
                                                            borderRadius: "100%"
                                                        }}
                                                        src={config.returnAvatar(
                                                            data.data.from
                                                        )}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </figure>
                                                <div className="name">
                                                    <h5>
                                                        {
                                                            data?.data.from
                                                                .full_name
                                                        }
                                                    </h5>
                                                    <p>{data?.data.message}</p>
                                                    <span>
                                                        {moment(
                                                            data?.data
                                                                .created_at
                                                        ).fromNow()}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <div className="buton-group">
                                        <button className="btn">Unblock</button>
                                    </div> */}
                                        </>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <Footer />
            {/* Footer */}
        </>
    );
};

export default Blocklist;
