import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FamilyTree, ProfilePic } from "../../constant";
import InputEmoji from "react-input-emoji";
import { connect } from "react-redux";
import Comment from "./Comment";
import config from "../../config";
import moment from "moment";
import { COMMENT_ENDPOINT, LIKE_ENDPOINT } from "../../apis";
import Slider from "react-slick";

const ReactionEmoji = [
    {
        id: 1,
        code: "&#128077;",
        value: "like"
    },
    {
        id: 2,
        code: "&#128514;",
        value: "laughing"
    },
    {
        id: 3,
        code: "&#128525;",
        value: "wow"
    },
    {
        id: 4,
        code: "&#129392;",
        value: "love"
    },
    {
        id: 5,
        code: "&#128554;",
        value: "sad"
    },
    {
        id: 6,
        code: "&#128545;",
        value: "angry"
    }
];

const Post = ({
    id,
    caption = null,
    media = [],
    comments = [],
    user,
    auth,
    created_at,
    liked_by_me = null
}) => {
    const [stateComments, setStateComments] = useState(comments || []);
    const [comment, setComment] = useState("");

    const submitComment = async () => {
        try {
            const headers = config.bearerHeaders(auth.token);
            const data = JSON.stringify({ comment });
            const res = await config.postApi(
                COMMENT_ENDPOINT(id),
                headers,
                data,
                false
            );
            if (res) {
                setComment("");
                setStateComments([res, ...stateComments]);
            }
        } catch (e) {
            console.log(e.errors);
        }
    };
    const Feeds = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const [likeId, setLikeId] = useState(liked_by_me?.id || null);
    const [reaction, setReaction] = useState(liked_by_me || null);

    const handleClick = async emoji => {
        if (likeId) {
            try {
                const headers = config.bearerHeaders(auth.token);
                const data = JSON.stringify({
                    reaction: emoji.value
                });
                const res = await config.putApi(
                    LIKE_ENDPOINT + "/" + likeId,
                    headers,
                    data,
                    false
                );
                if (res) {
                    setReaction(res);
                    setLikeId(res.id);
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            try {
                const headers = config.bearerHeaders(auth.token);
                const data = JSON.stringify({
                    like_on_type: "App\\Models\\Post",
                    like_on_id: id,
                    reaction: emoji.value
                });
                const res = await config.postApi(
                    LIKE_ENDPOINT,
                    headers,
                    data,
                    false
                );
                if (res) {
                    setReaction(res);
                    setLikeId(res.id);
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <div className="post-feed-wrapper">
            <div className="feed-profile-wrapper">
                <div className="user">
                    <div className="feed-img-wrapper">
                        <figure>
                            <img
                                src={config.returnAvatar(user)}
                                className="img-fluid"
                                alt=""
                            />
                        </figure>
                    </div>
                    <div className="feed-name-wrapper">
                        <h4>
                            {" "}
                            <a href="#">{user.full_name}</a>{" "}
                        </h4>
                        <h6>{moment(created_at).fromNow()}</h6>
                    </div>
                </div>
                {/* <div className="more-option-wrapper">
                    <div className="dropdown">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span>Sort By</span>
                            <i className="fa fa-ellipsis-v"></i>
                        </button>
                        <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <Link className="dropdown-item" to="#">
                                My posts
                            </Link>
                            <Link className="dropdown-item" to="#">
                                Family posts
                            </Link>
                            <Link className="dropdown-item" to="#">
                                Recent
                            </Link>
                            <Link className="dropdown-item" to="#">
                                Top voted (Year)
                            </Link>
                            <Link className="dropdown-item" to="#">
                                Top voted (All time)
                            </Link>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="feed-des-wrapper">
                {/* <button className="hash-tag">#relax, #travel</button> */}
                {config.formatHashTags(caption)}
            </div>
            {media.length > 0 ? (
                <div className="feed-img-wrapper feed_slider">
                    <Slider {...Feeds}>
                        {media[0].mime_type.split("/")[0] === "image" ? (
                            <figure>
                                <img
                                    src={
                                        process.env.REACT_APP_POST_URI +
                                        media[0].media_file
                                    }
                                    className="img-fluid"
                                    alt=""
                                />
                            </figure>
                        ) : (
                            <video
                                controls
                                style={{ maxWidth: "100%" }}
                                src={
                                    process.env.REACT_APP_POST_URI +
                                    media[0].media_file
                                }
                            />
                        )}
                    </Slider>
                </div>
            ) : null}
            <div className="comments-wrapper">
                {stateComments.map(stateComment => (
                    <Comment
                        {...stateComment}
                        key={stateComment.id.toString()}
                    />
                ))}
                {/* <div className="load-more">
                    <Link to="#">Load more</Link>
                </div> */}
            </div>
            <div className="post-comment-wrapper">
                <div className="like-wrapper">
                    <button>
                        {reaction ? (
                            <span
                                className="setEmoji"
                                dangerouslySetInnerHTML={{
                                    __html: ReactionEmoji.filter(
                                        singleReaction =>
                                            reaction.reaction ==
                                            singleReaction.value
                                    )[0].code
                                }}
                            ></span>
                        ) : (
                            <span>Like</span>
                        )}
                        <ul className="reactions">
                            {ReactionEmoji?.map((data, index) => {
                                return (
                                    <li
                                        key={`emoji_${data.id.toString()}`}
                                        onClick={e => handleClick(data)}
                                    >
                                        <span
                                            className="p-0"
                                            dangerouslySetInnerHTML={{
                                                __html: data.code
                                            }}
                                        ></span>
                                    </li>
                                );
                            })}
                        </ul>
                    </button>
                </div>
                <div className="post-content-wrapper">
                    <InputEmoji
                        value={comment}
                        onChange={setComment}
                        cleanOnEnter={false}
                        placeholder="Write comment here…"
                        onEnter={submitComment}
                    />
                </div>
                <div className="post-btn-wrapper">
                    <button
                        className="btn"
                        type="button"
                        onClick={submitComment}
                    >
                        Post
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Post);
