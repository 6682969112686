const helper = {
    createNode: singleUser => {
        // Partner Node
        var partnerNode = document.createElement("div");
        partnerNode.classList.add("partner_nodes");
        partnerNode.classList.add("d-flex");
        partnerNode.id = `partner_entries_${singleUser.id}`;

        // Sibling Node
        var siblingNode = document.createElement("div");
        siblingNode.classList.add("sibling_nodes");
        siblingNode.classList.add("d-flex");
        siblingNode.id = `sibling_entries_${singleUser.id}`;

        // Child Entries Node
        var childEntriesNode = document.createElement("div");
        childEntriesNode.classList.add("child_entries");
        childEntriesNode.classList.add("d-flex");
        childEntriesNode.id = `child_entries_${singleUser.id}`;

        // Inner Node
        var innerNode = document.createElement("div");
        innerNode.id = "node_inner_" + singleUser.id;
        innerNode.classList.add(
            singleUser.gender === "Male" ? "male_node" : "female_node"
        );
        innerNode.innerHTML = singleUser.name;

        // Outer Node
        var outerNode = document.createElement("div");
        outerNode.id = "node_outer_" + singleUser.id;

        // Add all nodes to outer node
        outerNode.appendChild(innerNode);
        outerNode.appendChild(childEntriesNode);
        outerNode.appendChild(partnerNode);
        return outerNode;
    }
};

export default helper;
