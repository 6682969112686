import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UPDATE_PROFILE_ENDPOINT } from "../../apis";
import "../../assets/css/profile.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import MyInput from "../../components/MyInput";
import MySelect from "../../components/MySelect";
import OverlaySpinner from "../../components/OverlaySpinner";
import config from "../../config";
import FormException from "../../exceptions/FormException";
import { UPDATE_PROFILE } from "../../redux/actions/type";

const EditProfile = () => {
    const authToken = useSelector(state => state.auth.token);
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();

    const [avatar, setAvatar] = useState({});
    const [uploadfile, setUploadfile] = useState(config.returnAvatar(user));
    const [fname, setFname] = useState(user.first_name ?? "");
    const [lname, setLname] = useState(user.last_name ?? "");
    const [dob, setDob] = useState(user.dob ?? "");
    const [gender, setGender] = useState(user.gender ?? "");
    const [email, setEmail] = useState(user.email ?? "");
    const [phone, setPhone] = useState(user.phone ?? "");
    const [password, setPassword] = useState("");
    const [cpassword, setCpassword] = useState("");

    const [spinner, setSpinner] = useState(false);
    const [errors, setErrors] = useState({});

    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            setUploadfile(URL.createObjectURL(event.target.files[0]));
            setAvatar(event.target.files[0]);
        }
    };
    function getExtension(filename) {
        return filename.split(".").pop();
    }

    const updateProfile = async () => {
        try {
            setSpinner(true);
            let data = new FormData();
            data.append("first_name", fname);
            data.append("last_name", lname);
            data.append("dob", dob);
            data.append("gender", gender);
            data.append("phone", phone);
            if (password && cpassword) {
                data.append("password", password);
                data.append("password_confirmation", cpassword);
            }
            if (avatar && avatar.name) {
                data.append("avatar", avatar);
            }
            const headers = config.bearerMultipartHeaders(authToken);
            const res = await config.postApi(
                UPDATE_PROFILE_ENDPOINT,
                headers,
                data
            );
            if (res) {
                dispatch({ type: UPDATE_PROFILE, payload: res });
            }
            setErrors({});
            resetForm();
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            if (e instanceof FormException) {
                setErrors(e.errors);
            }
        }
    };

    const resetForm = () => {
        setPassword("");
        setCpassword("");
        setAvatar({});
    };

    return (
        <>
            {/* Header */}
            <Header />
            {/* Header */}

            <section className="edit-profile">
                <div className="container">
                    <div className="topbar">
                        <h3>Edit Profile</h3>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="edit-profile-form">
                                <div className="close-btn">
                                    <Link to="/user-profile">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-x"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </Link>
                                </div>
                                <form action="/user-profile">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="change-avatar">
                                                {uploadfile ? (
                                                    <div className="avatar">
                                                        {getExtension !==
                                                            "png/jpg/jpeg/webp" ? (
                                                            <img
                                                                src={uploadfile}
                                                                width="100%"
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <>
                                                                <p>
                                                                    No file
                                                                    choosen
                                                                </p>
                                                            </>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="no_preview">
                                                        <img
                                                            className="img-fluid"
                                                            src={uploadfile}
                                                            alt=""
                                                        />
                                                    </div>
                                                )}
                                                <label htmlFor="FileUploader">
                                                    Change photo
                                                </label>
                                                <input
                                                    hidden
                                                    type="file"
                                                    onChange={onImageChange}
                                                    className="filetype"
                                                    id="FileUploader"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                label="First Name"
                                                inputId="first_name"
                                                value={fname}
                                                onChange={e =>
                                                    setFname(e.target.value)
                                                }
                                                showLabel
                                                errors={errors.first_name}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Last Name"
                                                inputId="last_name"
                                                value={lname}
                                                onChange={e =>
                                                    setLname(e.target.value)
                                                }
                                                showLabel
                                                errors={errors.last_name}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Date Of Birth"
                                                inputId="dob"
                                                value={dob}
                                                onChange={e =>
                                                    setDob(e.target.value)
                                                }
                                                showLabel
                                                type="date"
                                                errors={errors.dob}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MySelect
                                                placeholder="Select Gender"
                                                options={[
                                                    {
                                                        label: "Male",
                                                        value: "Male"
                                                    },
                                                    {
                                                        label: "Female",
                                                        value: "Female"
                                                    }
                                                ]}
                                                label="Gender"
                                                onChange={e =>
                                                    setGender(e.target.value)
                                                }
                                                value={gender}
                                                showLabel
                                                inputId="gender"
                                                errors={errors.gender}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Email"
                                                inputId="email"
                                                value={email}
                                                onChange={e =>
                                                    setEmail(e.target.value)
                                                }
                                                showLabel
                                                errors={errors.email}
                                                disabled
                                                type="email"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Phone"
                                                inputId="phone"
                                                value={phone}
                                                onChange={e =>
                                                    setPhone(e.target.value)
                                                }
                                                showLabel
                                                errors={errors.phone}
                                                type="tel"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Password"
                                                inputId="password"
                                                value={password}
                                                onChange={e =>
                                                    setPassword(e.target.value)
                                                }
                                                showLabel
                                                errors={errors.password}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Confirm Change Password"
                                                inputId="password_confirmation"
                                                value={cpassword}
                                                onChange={e =>
                                                    setCpassword(e.target.value)
                                                }
                                                showLabel
                                                errors={
                                                    errors.password_confirmation
                                                }
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="button-group form-group">
                                                <button
                                                    className="btn"
                                                    type="button"
                                                    onClick={updateProfile}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>

            {spinner && <OverlaySpinner />}
            {/* Footer */}
            <Footer />
            {/* Footer */}
        </>
    );
};

export default EditProfile;
