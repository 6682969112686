import { CLEAR_RELATIONS, GET_RELATIONS } from "../actions/type";

const relations = (state = [], action) => {
    switch (action.type) {
        case GET_RELATIONS:
            return action.payload;
        case CLEAR_RELATIONS:
            return [];
        default:
            return state;
    }
};

export default relations;
