import React from "react";
import moment from "moment";

const SentMessage = ({ message }) => {
    return (
        <div className="send-message">
            <div className="message-body">
                <span>{message.message}</span>
            </div>
            <div className="send-on">
                <span>{moment(message.created_at).format("hh:mm A")}</span>
            </div>
        </div>
    );
};

export default SentMessage;
