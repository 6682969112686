import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const MyCheckbox = ({
    value,
    onChange = null,
    errors = [],
    label = "",
    showLabel = false,
    inputId = ""
}) => {
    return (
        <FormGroup check>
            <Input
                id={inputId}
                type="checkbox"
                checked={value}
                onChange={onChange}
                invalid={errors.length > 0}
            />
            {showLabel && <Label htmlFor={inputId}>{label}</Label>}
            <FormFeedback>{errors.map(err => err)}</FormFeedback>
        </FormGroup>
    );
};

export default MyCheckbox;
