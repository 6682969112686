import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import PublicRoutes from "./routes/PublicRoutes";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import store from "../src/redux/store";

function App() {
    return (
        <>
            {/* App Js */}
            <Provider store={store.store}>
                <PersistGate persistor={store.persistedStore}>
                    <PublicRoutes />
                    <input
                        type="file"
                        id="picker"
                        style={{ display: "none" }}
                        accept="image/*"
                    />
                </PersistGate>
            </Provider>
            {/* App Js */}
        </>
    );
}

export default App;
