import React from 'react'
import Header from '../../components/Header/Header'
import Footer from "../../components/Footer/Footer"
import InnerBanner from "../../components/InnerBanner/InnerBanner"
import { CommentPic1, CommentPic2, CommentPic3, CommentPic4 } from '../../constant'
import "../../assets/css/search.css"
import { Link } from 'react-router-dom'

const SearchResult = () => {
    const ProfileData = [
        {
            id: 1,
            img: CommentPic1,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 2,
            img: CommentPic2,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 3,
            img: CommentPic3,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 4,
            img: CommentPic4,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 5,
            img: CommentPic1,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 6,
            img: CommentPic2,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 7,
            img: CommentPic3,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 8,
            img: CommentPic4,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 9,
            img: CommentPic1,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 10,
            img: CommentPic2,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 11,
            img: CommentPic3,
            name: "Smith Kell",
            username: "smithK_el@",
        },
        {
            id: 12,
            img: CommentPic4,
            name: "Smith Kell",
            username: "smithK_el@",
        },
    ]
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header Start Here */}

            {/* InnerBanner Start Here */}
            <InnerBanner PageName="Search Results" />
            {/* InnerBanner End Here */}

            <section className="search_results">
                <div className="container">
                    <div className="row">
                        {ProfileData?.map((data, index) => {
                            return (
                                <div className="col-lg-3 col-md-6" key={index}>
                                    <div className="profile_box">
                                        <Link to="/user-profile">
                                            <div className="profile-img">
                                                <figure><img src={data?.img} alt="" className="img-fluid" /></figure>
                                            </div>
                                            <div className="profile-name">
                                                <h4>{data?.name}</h4>
                                                <p>{data?.username}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            {/* Footer Start Here */}
            <Footer />
            {/* Header Start Here */}
        </>
    )
}

export default SearchResult
