import React, { useEffect } from "react";
import { connect } from "react-redux";
import { UPDATE_TIMELINE_ENDPOINT } from "../../apis";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import MyCheckbox from "../../components/MyCheckbox";
import config from "../../config";
import { GetRecentTimelineAction } from "../../redux/actions/timeline";
import DashboardSidebar from "./DashboardSidebar";

const RecentAddition = ({ auth, recentTimeline, getRecentTimeline }) => {
    useEffect(() => {
        loadTimeline().then();
    }, []);

    const loadTimeline = async () => {
        try {
            await getRecentTimeline();
        } catch (e) {
            console.log(e);
        }
    };

    const updateTimeline = async (timelineId, checked) => {
        try {
            const headers = config.bearerHeaders(auth.token);
            await config.postApi(
                UPDATE_TIMELINE_ENDPOINT(timelineId),
                headers,
                JSON.stringify({ long_term: checked })
            );
            await loadTimeline();
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Start Here */}
            <InnerBanner PageName="Dashboard" />
            {/* InnerBanner End Here */}

            {/* Dashboard Sec Start Here */}
            <section className="dashboard_sec">
                <div className="container">
                    <div className="admin_box">
                        <div className="row">
                            <div className="col-lg-3">
                                <DashboardSidebar />
                            </div>
                            <div className="col-lg-8">
                                <div className="recently_added">
                                    <div className="module_title">
                                        <h3>Recent Additions</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Content</th>
                                                    <th>Add tags</th>
                                                    <th>Store long term</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recentTimeline.data.map(
                                                    timeline => (
                                                        <tr
                                                            key={timeline.id.toString()}
                                                        >
                                                            <td>
                                                                {
                                                                    timeline
                                                                        ?.media[0]
                                                                        ?.media_type
                                                                }
                                                            </td>
                                                            <td>
                                                                {timeline.tags.join(
                                                                    "/"
                                                                )}
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        timeline.long_term
                                                                    }
                                                                    className="form-control"
                                                                    onChange={e =>
                                                                        updateTimeline(
                                                                            timeline.id,
                                                                            e
                                                                                .target
                                                                                .checked
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Dashboard Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    recentTimeline: state.timeline.recent
});

const mapDispatchToProps = dispatch => ({
    getRecentTimeline: async () => dispatch(await GetRecentTimelineAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentAddition);
