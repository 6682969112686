import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { VisaCard } from "../../constant";
import "../../assets/css/payment.css";
import {
    CardElement,
    Elements,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../config";
import { Spinner } from "reactstrap";
import {
    GetPaymentMethodsAction,
    AddPaymentMethodAction
} from "../../redux/actions/paymentMethods";
import { useDispatch, useSelector } from "react-redux";
import { PAYMENT_METHODS_ENDPOINT } from "../../apis";
import OverlaySpinner from "../../components/OverlaySpinner";

const PaymentInner = () => {
    const paymentMethods = useSelector(state => state.paymentMethods);
    const authToken = useSelector(state => state.auth.token);
    const dispatch = useDispatch();

    const [spinner, setSpinner] = useState(false);
    const [addingPm, setAddingPm] = useState(false);

    const [cardError, setCardError] = useState("");

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        getPaymentMethods().then();
    }, []);

    const getPaymentMethods = async () => {
        dispatch(await GetPaymentMethodsAction());
    };

    const addCard = async () => {
        try {
            setAddingPm(true);
            const { error, source } = await stripe.createSource(
                elements.getElement(CardElement),
                {
                    type: "card"
                }
            );
            if (error) {
                setCardError(error);
                setAddingPm(false);
                return false;
            }
            await dispatch(await AddPaymentMethodAction(source.id));
            setAddingPm(false);
        } catch (e) {
            setAddingPm(false);
            console.log(e);
        }
    };

    const makeDefault = async pm_id => {
        try {
            setSpinner(true);
            const headers = config.bearerHeaders(authToken);
            await config.putApi(
                PAYMENT_METHODS_ENDPOINT + "/" + pm_id,
                headers,
                JSON.stringify({ default_source: 1 })
            );
            await getPaymentMethods();
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Payment Section Start Here */}
            <div className="payment-details">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="heading">
                                <h3>Payment Methods</h3>
                            </div>
                            <div className="saved-payment-method">
                                <h5>Use Saved Payments</h5>
                                {paymentMethods.map(pm => {
                                    return (
                                        <label
                                            className="container"
                                            key={pm.id.toString()}
                                        >
                                            <span className="checkmark">
                                                <div className="card-details">
                                                    {/* <div className="radio-btn">
                                                        <span></span>
                                                    </div> */}
                                                    <div className="visa-card">
                                                        <div className="img-box">
                                                            <figure>
                                                                <img
                                                                    src={config.returnCardImage(
                                                                        pm.card_brand
                                                                    )}
                                                                    alt="No Image Found"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <div className="input-box">
                                                            <span id="card-number">
                                                                **********
                                                                {pm.card_last_4}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="extra-btn">
                                                        {pm.is_default ? (
                                                            <button className="btn default disabled">
                                                                Default
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn"
                                                                style={{
                                                                    backgroundColor:
                                                                        config.primaryColor,
                                                                    color:
                                                                        "#FFF"
                                                                }}
                                                                onClick={() =>
                                                                    makeDefault(
                                                                        pm.id
                                                                    )
                                                                }
                                                            >
                                                                Make Default
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </span>
                                        </label>
                                    );
                                })}
                                {/* <!-- Saved payments Method --> */}

                                <div className="add-new-method mt-3">
                                    <div className="form-group">
                                        <CardElement
                                            options={{
                                                style: {
                                                    base: { fontSize: "20px" },
                                                    complete: {
                                                        color:
                                                            config.primaryColor
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="button-group mt-4">
                                        <button
                                            className="btn"
                                            type="button"
                                            onClick={addCard}
                                            disabled={addingPm}
                                        >
                                            {addingPm ? (
                                                <Spinner />
                                            ) : (
                                                "Add Card"
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="card-type">
											<label className="container">
												<input type="radio" checked="checked" name="radio" />
												<span className="checkmark">
													<figure>
														<img src="img/pymnt1.png" className="img-fluid" />
													</figure>
												</span>
											</label>
											<label className="container">
												<input type="radio" name="radio" />
												<span className="checkmark">
													<figure>
														<img src="img/pymnt2.png" className="img-fluid" />
													</figure>
												</span>
											</label>
											<label className="container">
												<input type="radio" name="radio" />
												<span className="checkmark">
													<figure>
														<img src="img/pymnt3.png" className="img-fluid" />
													</figure>
												</span>
											</label>
											<label className="container">
												<input type="radio" name="radio" />
												<span className="checkmark">
													<figure>
														<img src="img/pymnt4.png" className="img-fluid" />
													</figure>
												</span>
											</label>
											<label className="container">
												<input type="radio" name="radio" />
												<span className="checkmark">
													<figure>
														<img src="img/pymnt5.png" className="img-fluid" />
													</figure>
												</span>
											</label>
										</div> */}
                                {/* <!-- Saved payments Method --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Payment Secrion End Here */}

            {spinner && <OverlaySpinner />}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const Payment = () => {
    return (
        <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)}>
            <PaymentInner />
        </Elements>
    );
};

export default Payment;
