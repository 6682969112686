import React from "react";
import "../../assets/css/profile.css";
import {
    CommentPic1,
    CommentPic2,
    CommentPic3,
    CommentPic4,
    FamilyTree,
    postimg1,
    postimg2,
    postimg3,
    postimg4,
    postimg5,
    postimg6,
    profileImg,
    ProfilePic
} from "../../constant";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../assets/css/user-profile.css";
import { Link } from "react-router-dom";
import InputEmoji from "react-input-emoji";
import { useState } from "react";

const UserProfile = () => {
    const [text, setText] = useState();
    let currentUrl = window.location.href.split("/");
    const Postes = [
        {
            id: 1,
            Image: postimg1
        },
        {
            id: 2,
            Image: postimg2
        },
        {
            id: 3,
            Image: postimg3
        },
        {
            id: 4,
            Image: postimg4
        },
        {
            id: 5,
            Image: postimg5
        },
        {
            id: 6,
            Image: postimg6
        }
    ];
    return (
        <>
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Profile Sec Start Here */}
            <section className="profile-sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="profile-info">
                                <div className="profile-img">
                                    <figure>
                                        <img
                                            src={profileImg}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </figure>
                                </div>
                                <div className="info">
                                    <h3 className="username">Cathline07</h3>
                                    <p>
                                        <span className="id">@jorgecutis</span>
                                        <span className="interest">
                                            Canna interest
                                        </span>
                                    </p>
                                    <h3 className="fullname">Cathline Karen</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="button-group">
                                <button className="btn message">Message</button>
                                {/* <button
                                    type="button"
                                    className="btn follow"
                                    data-bs-toggle="modal"
                                    data-bs-target="#unfollow"
                                >
                                    Unfollow
                                </button> */}
                            </div>
                        </div>
                    </div>

                    <div className="profile-post">
                        <ul
                            className="nav nav-pills mb-3"
                            id="pills-tab"
                            role="tablist"
                        >
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="Posts-tab"
                                    data-toggle="tab"
                                    href="#Posts"
                                    type="button"
                                    role="tab"
                                    aria-controls="Posts"
                                    aria-selected="true"
                                >
                                    Posts
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="images-tab"
                                    data-toggle="tab"
                                    href="#images"
                                    type="button"
                                    role="tab"
                                    aria-controls="images"
                                    aria-selected="false"
                                >
                                    images
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="Videos-tab"
                                    data-toggle="tab"
                                    href="#Videos"
                                    type="button"
                                    role="tab"
                                    aria-controls="Videos"
                                    aria-selected="false"
                                >
                                    Videos
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="Posts"
                                role="tabpanel"
                                aria-labelledby="Posts-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="post-feed-wrapper">
                                            <div className="feed-profile-wrapper">
                                                <div className="user">
                                                    <div className="feed-img-wrapper">
                                                        <figure>
                                                            <img
                                                                src={ProfilePic}
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div className="feed-name-wrapper">
                                                        <h4>
                                                            {" "}
                                                            <a href="#">
                                                                Paul Ceglia
                                                            </a>{" "}
                                                        </h4>
                                                        <h6>2 hr</h6>
                                                    </div>
                                                </div>
                                                <div className="more-option-wrapper">
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-secondary dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenuButton"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            <span>Sort By</span>
                                                            <i className="fa fa-ellipsis-v"></i>
                                                        </button>
                                                        <div
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton"
                                                        >
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                            >
                                                                My posts
                                                            </Link>
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                            >
                                                                Family posts
                                                            </Link>
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                            >
                                                                Recent
                                                            </Link>
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                            >
                                                                Top voted (Year)
                                                            </Link>
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                            >
                                                                Top voted (All
                                                                time)
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feed-des-wrapper">
                                                <button className="hash-tag">
                                                    #relax, #travel
                                                </button>
                                                <p>
                                                    Lorem ipsum dolor sit amet,
                                                    consectetur adipiscing elit.
                                                </p>
                                            </div>
                                            <div className="feed-img-wrapper">
                                                <figure>
                                                    <img
                                                        src={FamilyTree}
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </figure>
                                            </div>
                                            <div className="comments-wrapper">
                                                <div className="single-comment-wrapper">
                                                    <div className="single-comment-img-wrapper">
                                                        <img
                                                            src={CommentPic1}
                                                            className="comment-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="comment-content-wrapper">
                                                        <h6 className="profile-name">
                                                            <a href="#">
                                                                Howard Barton
                                                            </a>
                                                        </h6>
                                                        <p className="comment-des">
                                                            Lorem, ipsum dolor
                                                            sit amet consectetur
                                                            adipisicing elit.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="single-comment-wrapper">
                                                    <div className="single-comment-img-wrapper">
                                                        <img
                                                            src={CommentPic2}
                                                            className="comment-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="comment-content-wrapper">
                                                        <h6 className="profile-name">
                                                            <a href="#">
                                                                robintallrothh
                                                            </a>
                                                        </h6>
                                                        <p className="comment-des">
                                                            Lorem, ipsum dolor
                                                            sit amet consectetur
                                                            adipisicing elit.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="single-comment-wrapper">
                                                    <div className="single-comment-img-wrapper">
                                                        <img
                                                            src={CommentPic3}
                                                            className="comment-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="comment-content-wrapper">
                                                        <h6 className="profile-name">
                                                            <a href="#">
                                                                lauren{" "}
                                                            </a>
                                                        </h6>
                                                        <p className="comment-des">
                                                            Lorem, ipsum dolor
                                                            sit amet consectetur
                                                            adipisicing elit.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="single-comment-wrapper">
                                                    <div className="single-comment-img-wrapper">
                                                        <img
                                                            src={CommentPic4}
                                                            className="comment-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="comment-content-wrapper">
                                                        <h6 className="profile-name">
                                                            <a href="#">
                                                                michealholding2{" "}
                                                            </a>
                                                        </h6>
                                                        <p className="comment-des">
                                                            Lorem, ipsum dolor
                                                            sit amet consectetur
                                                            adipisicing elit.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="load-more">
                                                    <Link to="#">
                                                        Load more
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="post-comment-wrapper">
                                                <div className="post-content-wrapper">
                                                    <InputEmoji
                                                        value={text}
                                                        onChange={setText}
                                                        cleanOnEnter
                                                        placeholder="Write comment here…"
                                                    />
                                                </div>
                                                <div className="post-btn-wrapper">
                                                    <button className="btn">
                                                        Post
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-feed-wrapper">
                                            <div className="feed-profile-wrapper">
                                                <div className="user">
                                                    <div className="feed-img-wrapper">
                                                        <figure>
                                                            <img
                                                                src={ProfilePic}
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div className="feed-name-wrapper">
                                                        <h4>
                                                            {" "}
                                                            <a href="#">
                                                                Paul Ceglia
                                                            </a>{" "}
                                                        </h4>
                                                        <h6>2 hr</h6>
                                                    </div>
                                                </div>
                                                <div className="more-option-wrapper">
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-secondary dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenuButton"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            <span>Sort By</span>
                                                            <i className="fa fa-ellipsis-v"></i>
                                                        </button>
                                                        <div
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton"
                                                        >
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                            >
                                                                My posts
                                                            </Link>
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                            >
                                                                Family posts
                                                            </Link>
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                            >
                                                                Recent
                                                            </Link>
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                            >
                                                                Top voted (Year)
                                                            </Link>
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                            >
                                                                Top voted (All
                                                                time)
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feed-des-wrapper">
                                                <button className="hash-tag">
                                                    #relax, #travel
                                                </button>
                                                <p>
                                                    Lorem ipsum dolor sit amet,
                                                    consectetur adipiscing elit.
                                                </p>
                                            </div>
                                            <div className="feed-img-wrapper">
                                                <figure>
                                                    <img
                                                        src={FamilyTree}
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </figure>
                                            </div>
                                            <div className="comments-wrapper">
                                                <div className="single-comment-wrapper">
                                                    <div className="single-comment-img-wrapper">
                                                        <img
                                                            src={CommentPic1}
                                                            className="comment-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="comment-content-wrapper">
                                                        <h6 className="profile-name">
                                                            <a href="#">
                                                                Howard Barton
                                                            </a>
                                                        </h6>
                                                        <p className="comment-des">
                                                            Lorem, ipsum dolor
                                                            sit amet consectetur
                                                            adipisicing elit.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="single-comment-wrapper">
                                                    <div className="single-comment-img-wrapper">
                                                        <img
                                                            src={CommentPic2}
                                                            className="comment-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="comment-content-wrapper">
                                                        <h6 className="profile-name">
                                                            <a href="#">
                                                                robintallrothh
                                                            </a>
                                                        </h6>
                                                        <p className="comment-des">
                                                            Lorem, ipsum dolor
                                                            sit amet consectetur
                                                            adipisicing elit.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="single-comment-wrapper">
                                                    <div className="single-comment-img-wrapper">
                                                        <img
                                                            src={CommentPic3}
                                                            className="comment-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="comment-content-wrapper">
                                                        <h6 className="profile-name">
                                                            <a href="#">
                                                                lauren{" "}
                                                            </a>
                                                        </h6>
                                                        <p className="comment-des">
                                                            Lorem, ipsum dolor
                                                            sit amet consectetur
                                                            adipisicing elit.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="single-comment-wrapper">
                                                    <div className="single-comment-img-wrapper">
                                                        <img
                                                            src={CommentPic4}
                                                            className="comment-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="comment-content-wrapper">
                                                        <h6 className="profile-name">
                                                            <a href="#">
                                                                michealholding2{" "}
                                                            </a>
                                                        </h6>
                                                        <p className="comment-des">
                                                            Lorem, ipsum dolor
                                                            sit amet consectetur
                                                            adipisicing elit.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="load-more">
                                                    <Link to="#">
                                                        Load more
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="post-comment-wrapper">
                                                <div className="post-content-wrapper">
                                                    <InputEmoji
                                                        value={text}
                                                        onChange={setText}
                                                        cleanOnEnter
                                                        placeholder="Write comment here…"
                                                    />
                                                </div>
                                                <div className="post-btn-wrapper">
                                                    <button className="btn">
                                                        Post
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="images"
                                role="tabpanel"
                                aria-labelledby="images-tab"
                            >
                                <div className="row">
                                    {Postes?.map((postimg, index) => (
                                        <div
                                            key={index}
                                            className="col-lg-3 col-md-6"
                                        >
                                            <div className="img-box">
                                                <figure>
                                                    <img
                                                        src={postimg?.Image}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="Videos"
                                role="tabpanel"
                                aria-labelledby="Videos-tab"
                            >
                                <div className="row">
                                    {Postes?.map((postimg, index) => (
                                        <div
                                            key={index}
                                            className="col-lg-3 col-md-6"
                                        >
                                            <div className="img-box">
                                                <figure>
                                                    <img
                                                        src={postimg?.Image}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Profile Sec End Here */}

            {/* Footer */}
            <Footer />
            {/* Footer */}
        </>
    );
};

export default UserProfile;
