import React from "react";
import config from "../config";

const Spinner = ({ alignCenter = false }) => {
    return (
        <div className={"p-5 " + (alignCenter ? "text-center" : "")}>
            <div
                className="spinner-border"
                style={{ color: config.primaryColor }}
                role="status"
            >
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

export default Spinner;
