import { PAYMENT_METHODS_ENDPOINT } from "../../apis";
import config from "../../config";
import { ADD_PAYMENT_METHODS, GET_PAYMENT_METHODS } from "./type";

export const GetPaymentMethodsAction = () => async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const res = await config.getApi(PAYMENT_METHODS_ENDPOINT, headers);
    if (res) {
        dispatch({ type: GET_PAYMENT_METHODS, payload: res });
    }
};

export const AddPaymentMethodAction = paymentSource => async (
    dispatch,
    getState
) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const res = await config.postApi(
        PAYMENT_METHODS_ENDPOINT,
        headers,
        JSON.stringify({ payment_source: paymentSource })
    );
    if (res) {
        dispatch({ type: ADD_PAYMENT_METHODS, payload: res });
    }
};
